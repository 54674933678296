// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-ifp-gatsby-theme-shared-src-pages-test-2020-jsx": () => import("./../../node_modules/@ifp/gatsby-theme-shared/src/pages/test-2020.jsx" /* webpackChunkName: "component---node-modules-ifp-gatsby-theme-shared-src-pages-test-2020-jsx" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affordable-care-act-faq-jsx": () => import("./../../src/pages/affordable-care-act-faq.jsx" /* webpackChunkName: "component---src-pages-affordable-care-act-faq-jsx" */),
  "component---src-pages-dental-faq-jsx": () => import("./../../src/pages/dental-faq.jsx" /* webpackChunkName: "component---src-pages-dental-faq-jsx" */),
  "component---src-pages-frequently-asked-questions-jsx": () => import("./../../src/pages/frequently-asked-questions.jsx" /* webpackChunkName: "component---src-pages-frequently-asked-questions-jsx" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individual-and-family-faq-jsx": () => import("./../../src/pages/individual-and-family-faq.jsx" /* webpackChunkName: "component---src-pages-individual-and-family-faq-jsx" */),
  "component---src-pages-individual-and-family-jsx": () => import("./../../src/pages/individual-and-family.jsx" /* webpackChunkName: "component---src-pages-individual-and-family-jsx" */),
  "component---src-pages-life-insurance-faq-jsx": () => import("./../../src/pages/life-insurance-faq.jsx" /* webpackChunkName: "component---src-pages-life-insurance-faq-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-small-business-jsx": () => import("./../../src/pages/small-business.jsx" /* webpackChunkName: "component---src-pages-small-business-jsx" */),
  "component---src-pages-student-health-insurance-faq-jsx": () => import("./../../src/pages/student-health-insurance-faq.jsx" /* webpackChunkName: "component---src-pages-student-health-insurance-faq-jsx" */),
  "component---src-templates-amp-blog-post-amp-jsx": () => import("./../../src/templates/amp/BlogPostAmp.jsx" /* webpackChunkName: "component---src-templates-amp-blog-post-amp-jsx" */),
  "component---src-templates-author-info-template-jsx": () => import("./../../src/templates/AuthorInfoTemplate.jsx" /* webpackChunkName: "component---src-templates-author-info-template-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../src/templates/CategoryTemplate.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-email-campaign-post-template-jsx": () => import("./../../src/templates/EmailCampaignPostTemplate.jsx" /* webpackChunkName: "component---src-templates-email-campaign-post-template-jsx" */),
  "component---src-templates-guide-template-jsx": () => import("./../../src/templates/GuideTemplate.jsx" /* webpackChunkName: "component---src-templates-guide-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../src/templates/PageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */)
}

