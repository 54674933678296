export const truncateString = (str, num) => {
	if (str.length <= num) {
		return str
	}
	return str.slice(0, num)
}

export const setCookie = (cookieKey, cookieValue, expirationDays) => {
	let expiryDate = '';

	if (expirationDays) {
		const date = new Date();

		date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);

		expiryDate = `; expires=" ${date.toUTCString()}`;
	}

	document.cookie = `${cookieKey}=${cookieValue || ''}${expiryDate}; path=/`;
}

export const getCookie = (cookieKey) => {
	let cookieName = `${cookieKey}=`;

	let cookieArray = document.cookie.split(';');

	for (let cookie of cookieArray) {

		while (cookie.charAt(0) === ' ') {
			cookie = cookie.substring(1, cookie.length);
		}

		if (cookie.indexOf(cookieName) === 0) {
			return cookie.substring(cookieName.length, cookie.length);
		}
	}
}

export const _raf = () => {
	if (typeof window !== 'undefined') {
		return window.requestAnimationFrame ||
			window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			function (callback) {
				window.setTimeout(callback, 1000 / 60);
			};
	}
	return
}

export const isElInView = (ref) => {
	if (typeof window !== 'undefined') {

		const scroll = window.scrollY || window.pageYOffset;
		const boundsTop = ref.current.getBoundingClientRect().top + scroll;

		const viewport = {
			top: scroll,
			bottom: scroll + window.innerHeight
		};

		const bounds = {
			top: boundsTop,
			bottom: boundsTop + ref.current.clientHeight
		};

		return (
			(bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
			(bounds.top <= viewport.bottom && bounds.top >= viewport.top)
		);
	}
	return
};