/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "typeface-muli"
import "typeface-libre-baskerville"
import "typeface-open-sans"
import "typeface-poppins"
import "ehi-pillar-components/www/styles/ehi-pillar/ehi-pillar.min.css"
import "./src/styles/main.scss"

import queryString from "query-string"
import {
	setCookie
} from "./src/utils/helpers"

export const onRouteUpdate = ({
	location
}) => {
	// Look for params to add cookies
	const cookies = queryString.parse(location.search)
	const {
		allid,
		pid,
		sid,
		campaign
	} = cookies

	// If params, set cookie
	if (allid) setCookie("ehi.alliance_id", allid, 30)
	if (pid) setCookie("ehi.alliance_pid", pid)
	if (sid) setCookie("ehi.alliance_sid", sid)
	if (campaign) setCookie("ehi.alliance_campaign", campaign)
}